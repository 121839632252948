
















































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      show: false,
      email: "",
      password: "",
      valid: false,
      rules: {
        required: (value: string) => !!value || "Pflichtfeld",
        emailMatch: () => "Die Email und das Passwort stimmen nicht über ein",
      },
    };
  },
  methods: {
    async login() {
      let raw = JSON.stringify({
        username: this.email,
        password: this.password,
      });

      let requestOptions = {
        method: "POST",
        body: raw,
      };
      let response = await fetch(
        "https://mdic-backend.herokuapp.com/login",
        requestOptions
      );
      let token = await response.text();
      if (token.startsWith("Bearer ")) {
        this.$store.dispatch("setToken", token);
        this.$router.push("/");
      }
    },
  },
});
